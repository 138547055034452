// Dependencies
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
// Components
import SEO from '../components/seo'
import Swiper from '../components/swiper'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import Picture from '../components/picture'
import PricingTable from '../components/pricingTable'

const Aside = ({ title, gallery, copy, details, id }) => {

  return (
    <div className="section-with-aside">
      <aside>
        <Markdown html={copy.childMarkdownRemark.html} />
      </aside>
      <div className={`${!details ? 'images' : 'details'}`}>
        {gallery ? (
          <div className="inlb prel w100 gallery">
            <Swiper className="inline-gallery" scrollMultiplier={1} id={`${id}-swiper`}>
              {gallery.map((slide, index) => {
                return (
                  <div key={`slide-${index}`}>
                    <Picture className={true ? 'img-cover' : 'img-contain'} {...slide.fluid} />
                    {slide.description && <p className="description">{`[${index + 1}/${gallery.length}] ${slide.description}`}</p>}
                  </div>
                )
              })}
            </Swiper>
          </div>
        ) : details && (
          <PricingTable field={details} />
        )}
      </div>
    </div>
  )
}

const Service = ({ title, copy, preview }) => {

  const element = useRef();
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0});

  const handleMouseMove = e => {
    setPosition({
      x: e.clientX,
      y: element.current.getBoundingClientRect().top
    });
  }

  return (
    <div
      role="complementary"
      className="service"
      onMouseEnter={e => setShow(true)}
      onMouseLeave={e => setShow(false)}
      onMouseMove={handleMouseMove}
      ref={element}
    >
      {preview && show && (
        <div className="service-preview" style={{
          top: position.y,
          left: position.x
        }}>
          <video src={preview.file.url} autoPlay loop muted playsInline />
        </div>
      )}
      <h3 style={{
        cursor: preview ? 'help' : 'auto'
      }}>
        {title}
      </h3>
      <Markdown html={copy.childMarkdownRemark.html} />
    </div>
  )
}

const ServicePage = ({ data }) => {

  const homepage = data.allContentfulHomepage.edges[0].node
  const {
    pageTitle,
    masthead,
    sections,
    signOff
  } = data.contentfulServicesPage

  return (
    <Layout
      {...homepage}
      masthead={masthead}
      // fgColor="#BE89FF"
      // bgColor="#F1E4FF"
      hasFooter
    >
      <SEO title={`Make–Ready | ${pageTitle}`} />

      {sections && sections.map((section) => (
        <div className="outerx8" key={section.id}>

          {section.gallery && section.copy ? (
            <section className="service-row">
              <Aside {...section} id={`${section.id}-swiper`} />
            </section>
          ) : section.gallery && (
            <Swiper className="full-width-gallery" id={`${section.id}-swiper`}>
              {section.gallery.map((slide, index) => {
                return (
                  <div key={`slide-${index}`}>
                    <Picture className={true ? 'img-cover' : 'img-contain'} {...slide.fluid} />
                    {slide.description && <p className="description outer">{`[${index + 1}/${section.gallery.length}] ${slide.description}`}</p>}
                  </div>
                )
              })}
            </Swiper>
          )}

          {section.details && section.copy ? (
            <section className="service-row">
              <Aside {...section} />
            </section>
          ) : section.details && (
            <section className="service-row">
              <h3 className="guttersx4">{section.title}</h3>
              <PricingTable field={section.details} />
            </section>
          )}

          {section.services && (
            <section className="service-row">
              <div className="services-list">
                {section.services.map((node, i) => (
                  <Service {...node} key={`item-${i}`} />
                ))}
              </div>
            </section>
          )}

        </div>
      ))}

      {signOff && (
        <div className="outerx8">
          <section className="innerx4 container">
            <div className="max54 center">
              <Markdown html={signOff.childMarkdownRemark.html} />
            </div>
          </section>
        </div>
      )}

    </Layout>
  )
}

ServicePage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ServicePage

export const pageQuery = graphql`
  query serviceQuery($slug: String!) {
    contentfulServicesPage(slug: {eq: $slug}) {
      pageTitle
      ## Masthead
      masthead {
        childMarkdownRemark {
          html
        }
      }
      ## Sections, collection of ContentfulService
      sections {
        title
        gallery {
          description
          fluid(maxWidth: 1200, quality: 80) {
            sizes
            srcSet
            aspectRatio
          }
        }
        #### Text column
        copy {
          childMarkdownRemark {
            html
          }
        }
        #### Price table
        details {
          childMarkdownRemark {
            html
          }
        }
        #### Collection of services
        services {
          title
          copy {
            childMarkdownRemark {
              html
            }
          }
          preview {
            file {
              url
            }
          }
        }
      }
      ## Sign off copy
      signOff {
        childMarkdownRemark {
          html
        }
      }
    }

    # ---------------
    # General settings content
    allContentfulHomepage (
      limit: 1
    ) {
      edges {
        node {
          tagline
          contactDetails {
            childMarkdownRemark {
              html
            }
          }
          mapLink
          email
          telephone
          projectFooterCopy {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
