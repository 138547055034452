// Dependencies
import React from 'react';
// Components
import Markdown from './markdown';

const PricingTable = ({ field }) => {

  return (
    <div className="pricing pre-scroll">
      <div className="pricing-wrapper">
        <Markdown html={field.childMarkdownRemark.html} />
      </div>
    </div>
  )
}

export default PricingTable
